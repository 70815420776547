import { Connection, Transaction, VersionedTransaction } from '@solana/web3.js';
import { PhantomSolanaProvider } from '../../types';
import { WalletContextState } from '@solana/wallet-adapter-react';

const signAndSendTransaction = async (
  transaction: Transaction | VersionedTransaction,
  provider?: PhantomSolanaProvider,
  walletContext?: WalletContextState,
  connection?: Connection
): Promise<string> => {
  try {
    if (provider) return (await provider.signAndSendTransaction(transaction)).signature;
    if (walletContext && connection)
      return await walletContext.sendTransaction(transaction, connection, { skipPreflight: false });
    throw new Error('No provider or wallet context found');
  } catch (error) {
    console.warn(error);
    throw new Error(error.message);
  }
};

export default signAndSendTransaction;
