// Switches chain to the requested chain ID if necessary, rejects if unsuccessful.
import { PhantomEthereumProvider } from '../../types';
import getEthereumChain from './getEthereumChain';
import switchEthereumChain from './switchEthereumChain';
import getChainName from './getChainName';
import { SupportedEVMChainIds } from '../../constants/chains';
import { TLog } from '../../types';

export const ensureEthereumChain = async (
  provider: PhantomEthereumProvider,
  chainId: SupportedEVMChainIds,
  createLog: (log: TLog) => void
): Promise<boolean> => {
  try {
    const curChainId = await getEthereumChain(provider);
    if (curChainId === chainId) {
      return true;
    }
    await switchEthereumChain(provider, chainId);
    createLog({
      providerType: 'ethereum',
      status: 'success',
      method: 'wallet_switchEthereumChain',
      message: `Switched to ${getChainName(chainId)} (Chain ID: ${chainId})`,
    });
    return true;
  } catch (error) {
    createLog({
      providerType: 'ethereum',
      status: 'error',
      method: 'wallet_switchEthereumChain',
      message: error.message,
    });
    return false;
  }
};
