import React, { useEffect, useState } from 'react';
import Sidebar from '../../components/Sidebar';
import { AppWrapper } from '../../components/AppWrapper';
import styled from 'styled-components';

const BlinksContainer = styled.div`
  max-width: 30%;
`;

const sleep = (timeInMS) => new Promise((resolve) => setTimeout(resolve, timeInMS));
const loadMessage = 'Loading the Blink...';

const App = () => {
  const blinkComponent = (
    <div data-testid="card.wrapper">
      <div>
        <a href="https://t.co/49mnQrB6m0">{loadMessage}</a>
      </div>
    </div>
  );
  const [show, setShow] = useState(false);
  useEffect(() => {
    (async () => {
      // sleep for 2000 ms to give time to inject
      await sleep(2000);
      setShow(true);
    })();
  }, []);

  return (
    <AppWrapper>
      <Sidebar disableLogs={true} logsVisibility={false} toggleLogs={() => {}} activePath="/blinks-sandbox">
        <BlinksContainer>{show ? blinkComponent : loadMessage}</BlinksContainer>
      </Sidebar>
    </AppWrapper>
  );
};

export default App;
