import React from 'react';
import { createRoot } from 'react-dom/client';

import App from './App';

import * as buffer from 'buffer';
import { DevProvider } from './hooks/useDev';
window.Buffer = buffer.Buffer;

const container = document.getElementById('react-root');
const root = createRoot(container);
root.render(
  <DevProvider>
    <App />
  </DevProvider>
);
