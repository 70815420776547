import { TransactionResponse, Web3Provider } from '@ethersproject/providers';
import { utils } from 'ethers';
import { ERC_20_TOKENS } from '../../constants/tokens';
import { parseEther } from 'viem';

/**
 * Sends 1 wei to yourself
 * @param {Web3Provider} provider a web3 provider object
 * @returns {Promise<TransactionResponse>} a raw transaction object (hasn't been confirmed by network)
 */
async function mintToken(chainId: string, provider: Web3Provider): Promise<TransactionResponse> {
  try {
    const signer = provider.getSigner();
    const address = await signer.getAddress();
    const gasPrice = await provider.getGasPrice();
    const tokenAddress = ERC_20_TOKENS[chainId];

    if (!tokenAddress) {
      throw new Error('Token address not found');
    }

    // Encode the function signature and parameters
    const amount = parseEther('100');
    const mintFunctionSignature = utils.id('mint(uint256,address)').slice(0, 10);
    const encodedParameters = utils.defaultAbiCoder.encode(['uint256', 'address'], [amount, address]);
    const data = mintFunctionSignature + encodedParameters.slice(2); // Remove the '0x' from encodedParameters

    const transactionParameters = {
      gasPrice, // customizable by user during confirmation.
      gasLimit: utils.hexlify(100000),
      to: tokenAddress, // Required except during contract publications.
      from: address,
      value: 0, // Only required to send ether to the recipient from the initiating external account.
      data: data, // Call to mint(amount, address) function,
      chainId: parseInt(chainId),
    };

    return signer.sendTransaction(transactionParameters);
  } catch (error) {
    console.warn(error);
    throw new Error(error.message);
  }
}

export default mintToken;
