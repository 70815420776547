import React from 'react';
import styled from 'styled-components';
import { GRAY, REACT_GRAY, PURPLE, WHITE } from '../../constants';

import { hexToRGB } from '../../utils';

import Button from '../Button';
import { Header } from '../Header';
import { ToggleDevSettings } from '../ToggleDevSettings';
import { MadeWithLoveAtPhantom } from '../MadeWithLoveAtPhantom';

// =============================================================================
// Styled Components
// =============================================================================

const Main = styled.main`
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
  align-items: center;
  background-color: ${REACT_GRAY};
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  max-height: 100vh;
  > * {
    margin-bottom: 10px;
    width: 100%;
  }
  @media (max-width: 768px) {
    width: 100%;
    height: auto;
  }
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
`;

const Tag = styled.p`
  text-align: center;
  color: ${GRAY};
  a {
    color: ${PURPLE};
    text-decoration: none;
    ::selection {
      color: ${WHITE};
      background-color: ${hexToRGB(PURPLE, 0.5)};
    }
    ::-moz-selection {
      color: ${WHITE};
      background-color: ${hexToRGB(PURPLE, 0.5)};
    }
  }
  @media (max-width: 320px) {
    font-size: 14px;
  }
  ::selection {
    color: ${WHITE};
    background-color: ${hexToRGB(PURPLE, 0.5)};
  }
  ::-moz-selection {
    color: ${WHITE};
    background-color: ${hexToRGB(PURPLE, 0.5)};
  }
`;

const ToggleLogsButton = styled(Button)`
  margin-bottom: 10px;
  padding: 8px 10px;
  background-color: ${GRAY};
  color: ${WHITE};
  border: none;
  border-radius: 6px;
  cursor: pointer;
  &:hover {
    background-color: ${hexToRGB(GRAY, 0.8)};
  }
  width: 100px;
`;

const MenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
`;

const Menu = styled.div`
  margin: 12px 0;
`;

// =============================================================================
// Typedefs
// =============================================================================

interface Props {
  children?: React.ReactNode;
  topSection?: React.ReactNode;
  disableLogs?: boolean;
  logsVisibility: boolean;
  toggleLogs: () => void;
  activePath: string;
}

// =============================================================================
// Main Component
// =============================================================================

const Sidebar = React.memo((props: Props) => {
  const { logsVisibility, toggleLogs, disableLogs } = props;

  return (
    <Main>
      <Body>
        <Header activePath={props.activePath} />
        <Menu>
          <MenuContainer>
            {!disableLogs && (
              <ToggleLogsButton onClick={toggleLogs}>{`${
                logsVisibility === true ? 'Hide' : 'Show'
              } Logs`}</ToggleLogsButton>
            )}
            {props.topSection}
          </MenuContainer>
        </Menu>

        {props.children}
      </Body>
      <div>
        <MadeWithLoveAtPhantom />
        <ToggleDevSettings />
      </div>
    </Main>
  );
});

export default Sidebar;
