import { Web3Provider } from '@ethersproject/providers';
import { ERC_20_TOKENS, TOKEN_LOCKER } from '../../constants/tokens';
import { parseEther } from 'viem';
import { getPermit2 } from './getPermit';
import { SUPPORTED_CHAINS } from '../../constants/chains';

export async function signPermit2ERC20(
  chainId: string,
  provider: Web3Provider,
  amount = parseEther('2')
): Promise<string> {
  try {
    const signer = provider.getSigner();
    const address = await signer.getAddress();
    const tokenAddress = ERC_20_TOKENS[chainId];
    const locker = TOKEN_LOCKER[chainId];

    if (!tokenAddress || !locker) {
      throw new Error('Token address not found');
    }

    const expiry = Math.floor((Date.now() + 1000 * 60 * 60 * 24 * 365) / 1000).toString();

    const message = getPermit2(
      SUPPORTED_CHAINS[chainId].hexChainId,
      amount.toString(),
      locker,
      locker,
      tokenAddress,
      expiry,
      '1',
      expiry
    );

    console.log(message);

    return provider.provider.request({
      method: 'eth_signTypedData_v4',
      params: [address, message],
    });
  } catch (error) {
    console.warn(error);
    throw new Error(error.message);
  }
}
