import * as React from 'react';

export function useLocalStorage<S>(
  key: string,
  initialValue?: S | (() => S)
): [S, React.Dispatch<React.SetStateAction<S>>] {
  const [storedValue, setStoredValue] = React.useState<S>(() => {
    const getInitialValue = () => {
      if (initialValue instanceof Function) {
        return initialValue();
      }
      return initialValue;
    };

    try {
      const item = window.localStorage.getItem(key);
      return item ? JSON.parse(item) : getInitialValue();
    } catch (error) {
      console.error(error);
      return getInitialValue();
    }
  });

  React.useEffect(() => {
    try {
      window.localStorage.setItem(key, JSON.stringify(storedValue));
    } catch (error) {
      console.error(error);
    }
  }, [key, storedValue]);

  return [storedValue, setStoredValue];
}
