import * as React from 'react';
import styled from 'styled-components';
import { GRAY, PURPLE, WHITE } from '../constants';
import { hexToRGB } from '../utils';

const Tag = styled.p`
  text-align: center;
  color: ${GRAY};

  a {
    color: ${PURPLE};
    text-decoration: none;

    ::selection {
      color: ${WHITE};
      background-color: ${hexToRGB(PURPLE, 0.5)};
    }

    ::-moz-selection {
      color: ${WHITE};
      background-color: ${hexToRGB(PURPLE, 0.5)};
    }
  }

  @media (max-width: 320px) {
    font-size: 14px;
  }

  ::selection {
    color: ${WHITE};
    background-color: ${hexToRGB(PURPLE, 0.5)};
  }

  ::-moz-selection {
    color: ${WHITE};
    background-color: ${hexToRGB(PURPLE, 0.5)};
  }
`;

export const MadeWithLoveAtPhantom = () => (
  <div>
    <Tag>
      Made with{' '}
      <span role="img" aria-label="Red Heart Emoji">
        ❤️
      </span>{' '}
      by the <a href="https://phantom.app">Phantom</a> team
    </Tag>
  </div>
);
