/**
 * @DEV: If the sandbox is throwing dependency errors, chances are you need to clear your browser history.
 * This will trigger a re-install of the dependencies in the sandbox – which should fix things right up.
 * Alternatively, you can fork this sandbox to refresh the dependencies manually.
 */

import * as React from 'react';
import { ConnectionProvider, useConnection, useWallet, WalletProvider } from '@solana/wallet-adapter-react';
import { WalletAdapterNetwork, WalletConnectionError } from '@solana/wallet-adapter-base';
import { WalletModalProvider, WalletMultiButton } from '@solana/wallet-adapter-react-ui';

import {
  createAddressLookupTable,
  createSignInData,
  createSignInErrorData,
  createTransferTransaction,
  createTransferTransactionV0,
  extendAddressLookupTable,
  pollSolanaSignatureStatus,
  signAllTransactions,
  signAndSendTransaction,
  signAndSendTransactionV0WithLookupTable,
  signIn,
  signMessage,
  signTransaction,
} from '../../utils/sol';

import Sidebar from '../../components/Sidebar';
import { SolanaNetworkSelector } from '../../components/NetworkSelector';
import { SupportedSolanaChainIds } from '../../constants/chains';
import { ActionButtons } from '../../components/Sidebar/ActionButtons';
import { LogsProvider, useLogs } from '../../hooks/useLogs';
import { Logs } from '../../components/Logs';
import { AppWrapper } from '../../components/AppWrapper';
import { ConnectedAs } from '../../components/Sidebar/ConnectedAs';
import { CheckboxWithLabel } from '../../components/CheckboxWithLabel';
import { PreferencesProvider, usePreferences } from '../../hooks/usePreferences';

require('@solana/wallet-adapter-react-ui/styles.css');

// =============================================================================
// Constants
// =============================================================================

const message = 'To avoid digital dognappers, sign below to authenticate with CryptoCorgis.';

const getConnectionUrl = (network: SupportedSolanaChainIds): string => {
  switch (network) {
    case SupportedSolanaChainIds.SolanaDevnet:
      // NB: This URL will only work for Phantom sandbox apps! Please do not use this for your project.
      return process.env.REACT_APP_SOLANA_DEVNET_RPC;
    case SupportedSolanaChainIds.SolanaMainnet:
      // NB: This URL will only work for Phantom sandbox apps! Please do not use this for your project.
      return process.env.REACT_APP_SOLANA_MAINNET_RPC;
    default:
      throw new Error(`Invalid network: ${network}`);
  }
};

// =============================================================================
// Components
// =============================================================================

const StatelessApp = ({ network, setNetwork }) => {
  const walletContext = useWallet();
  const { publicKey, wallet, connecting } = walletContext;
  const { connection } = useConnection();
  const { logs, createLog, clearLogs, toggleLogs, logsVisibility } = useLogs();
  const { autoConnect, setAutoConnect } = usePreferences();

  React.useEffect(() => {
    if (connecting) {
      createLog({
        status: 'info',
        method: 'connect',
        message: 'Connecting...',
      });
    }
  }, [connecting, createLog]);

  React.useEffect(() => {
    if (!publicKey || !wallet) return;

    createLog({
      status: 'success',
      method: 'connect',
      message: `Connected to account ${publicKey.toBase58()}`,
    });
  }, [createLog, publicKey, wallet]);

  /** SignAndSendTransaction */
  const handleSignAndSendTransaction = React.useCallback(async () => {
    if (!publicKey || !wallet) return;

    try {
      const transaction = await createTransferTransaction(publicKey, connection);
      createLog({
        status: 'info',
        method: 'signAndSendTransaction',
        message: `Requesting signature for: ${JSON.stringify(transaction)}`,
      });
      const signature = await signAndSendTransaction(transaction, undefined, walletContext, connection);
      createLog({
        status: 'info',
        method: 'signAndSendTransaction',
        message: `Signed and submitted transaction ${signature}.`,
      });
      pollSolanaSignatureStatus([signature], connection, createLog);
    } catch (error) {
      createLog({
        status: 'error',
        method: 'signAndSendTransaction',
        message: error.message,
      });
    }
  }, [connection, createLog, publicKey, wallet, walletContext]);

  /** SignAndSendTransactionV0 */
  const handleSignAndSendTransactionV0 = React.useCallback(async () => {
    if (!publicKey || !wallet) return;

    try {
      const transactionV0 = await createTransferTransactionV0(publicKey, connection);
      createLog({
        status: 'info',
        method: 'signAndSendTransactionV0',
        message: `Requesting signature for: ${JSON.stringify(transactionV0)}`,
      });
      const signature = await signAndSendTransaction(transactionV0, undefined, walletContext, connection);
      createLog({
        status: 'info',
        method: 'signAndSendTransactionV0',
        message: `Signed and submitted transactionV0 ${signature}.`,
      });
      pollSolanaSignatureStatus([signature], connection, createLog);
    } catch (error) {
      createLog({
        status: 'error',
        method: 'signAndSendTransactionV0',
        message: error.message,
      });
    }
  }, [connection, createLog, publicKey, wallet, walletContext]);

  /** SignAndSendTransactionV0WithLookupTable */
  const handleSignAndSendTransactionV0WithLookupTable = React.useCallback(async () => {
    if (!publicKey || !wallet) return;
    try {
      const [lookupSignature, lookupTableAddress] = await createAddressLookupTable(
        publicKey,
        connection,
        await connection.getLatestBlockhash().then((res) => res.blockhash),
        undefined,
        walletContext
      );
      createLog({
        status: 'info',
        method: 'signAndSendTransactionV0WithLookupTable',
        message: `Signed and submitted transactionV0 to make an Address Lookup Table ${lookupTableAddress} with signature: ${lookupSignature}. Please wait for 5-7 seconds after signing the next transaction to be able to see the next transaction popup. This time is needed as newly appended addresses require one slot to warmup before being available to transactions for lookups.`,
      });
      pollSolanaSignatureStatus([lookupSignature], connection, createLog);
      const extensionSignature = await extendAddressLookupTable(
        publicKey,
        connection,
        await connection.getLatestBlockhash().then((res) => res.blockhash),
        lookupTableAddress,
        undefined,
        walletContext
      );
      createLog({
        status: 'info',
        method: 'signAndSendTransactionV0WithLookupTable',
        message: `Signed and submitted transactionV0 to extend Address Lookup Table ${extensionSignature}.`,
      });
      pollSolanaSignatureStatus([extensionSignature], connection, createLog);
      const signature = await signAndSendTransactionV0WithLookupTable(
        publicKey,
        connection,
        await connection.getLatestBlockhash().then((res) => res.blockhash),
        lookupTableAddress,
        undefined,
        walletContext
      );
      createLog({
        status: 'info',
        method: 'signAndSendTransactionV0WithLookupTable',
        message: `Signed and submitted transactionV0 with Address Lookup Table ${signature}.`,
      });
      pollSolanaSignatureStatus([signature], connection, createLog);
    } catch (error) {
      createLog({
        status: 'error',
        method: 'signAndSendTransactionV0WithLookupTable',
        message: error.message,
      });
    }
  }, [connection, createLog, publicKey, wallet, walletContext]);

  /** SignTransaction */
  const handleSignTransaction = React.useCallback(async () => {
    if (!publicKey || !wallet) return;

    try {
      const transaction = await createTransferTransaction(publicKey, connection);
      createLog({
        status: 'info',
        method: 'signTransaction',
        message: `Requesting signature for: ${JSON.stringify(transaction)}`,
      });
      const signedTransaction = await signTransaction(transaction, undefined, walletContext);
      createLog({
        status: 'success',
        method: 'signTransaction',
        message: `Transaction signed: ${JSON.stringify(signedTransaction)}`,
      });
    } catch (error) {
      createLog({
        status: 'error',
        method: 'signTransaction',
        message: error.message,
      });
    }
  }, [connection, createLog, publicKey, wallet, walletContext]);

  /** SignAllTransactions */
  const handleSignAllTransactions = React.useCallback(async () => {
    if (!publicKey || !wallet) return;

    try {
      const transactions = [
        await createTransferTransaction(publicKey, connection),
        await createTransferTransaction(publicKey, connection),
      ];
      createLog({
        status: 'info',
        method: 'signAllTransactions',
        message: `Requesting signature for: ${JSON.stringify(transactions)}`,
      });
      const signedTransactions = await signAllTransactions(
        [transactions[0], transactions[1]],
        undefined,
        walletContext
      );
      createLog({
        status: 'success',
        method: 'signAllTransactions',
        message: `Transactions signed: ${JSON.stringify(signedTransactions)}`,
      });
    } catch (error) {
      createLog({
        status: 'error',
        method: 'signAllTransactions',
        message: error.message,
      });
    }
  }, [connection, createLog, publicKey, wallet, walletContext]);

  /** SignMessage */
  const handleSignMessage = React.useCallback(async () => {
    if (!publicKey || !wallet) return;

    try {
      const signedMessage = await signMessage(message, undefined, walletContext);
      createLog({
        status: 'success',
        method: 'signMessage',
        message: `Message signed: ${JSON.stringify(signedMessage)}`,
      });
      return signedMessage;
    } catch (error) {
      createLog({
        status: 'error',
        method: 'signMessage',
        message: error.message,
      });
    }
  }, [createLog, publicKey, wallet, walletContext]);

  /** SignIn */
  const handleSignIn = React.useCallback(async () => {
    if (!!publicKey || !wallet) return;
    const signInData = await createSignInData();

    try {
      const { account, signedMessage, signature } = await signIn(signInData, undefined, walletContext);
      const message = new TextDecoder().decode(signedMessage);
      const decodedSignature = new TextDecoder().decode(signature);
      createLog({
        status: 'success',
        method: 'signIn',
        message: `Message signed: ${message} by ${account.address} with signature ${decodedSignature}`,
      });
    } catch (error) {
      createLog({
        status: 'error',
        method: 'signIn',
        message: error.message,
      });
    }
  }, [createLog, publicKey, wallet, walletContext]);

  /** SignInError */
  const handleSignInError = React.useCallback(async () => {
    if (!!publicKey || !wallet) return;
    const signInData = await createSignInErrorData();

    try {
      const { account, signedMessage, signature } = await signIn(signInData, undefined, walletContext);
      const message = new TextDecoder().decode(signedMessage);
      const decodedSignature = new TextDecoder().decode(signature);
      createLog({
        status: 'success',
        method: 'signIn',
        message: `Message signed: ${message} by ${account.address} with signature ${decodedSignature}`,
      });
    } catch (error) {
      createLog({
        status: 'error',
        method: 'signIn',
        message: error.message,
      });
    }
  }, [createLog, publicKey, wallet, walletContext]);

  /** Disconnect */
  const handleDisconnect = React.useCallback(async () => {
    if (!publicKey || !wallet) return;

    try {
      await walletContext.disconnect();
      createLog({
        status: 'warning',
        method: 'disconnect',
        message: '👋',
      });
    } catch (error) {
      createLog({
        status: 'error',
        method: 'disconnect',
        message: error.message,
      });
    }
  }, [createLog, publicKey, wallet, walletContext]);

  /** Network Switch */
  const handleNetworkSwitch = React.useCallback(
    async (newNetwork: WalletAdapterNetwork) => {
      try {
        setNetwork(newNetwork);
        createLog({
          status: 'success',
          message: `Switched to ${newNetwork} network`,
        });
      } catch (error) {
        console.log('catch error');
        createLog({
          status: 'error',
          message: error.message,
        });
      }
    },
    [createLog, setNetwork]
  );

  const connectedMethods = React.useMemo(() => {
    return [
      {
        name: 'Sign and Send Transaction (Legacy)',
        chainIds: [SupportedSolanaChainIds.SolanaMainnet],
        onClick: handleSignAndSendTransaction,
      },
      {
        name: 'Sign and Send Transaction (v0)',
        chainIds: [SupportedSolanaChainIds.SolanaMainnet],
        onClick: handleSignAndSendTransactionV0,
      },
      {
        name: 'Sign and Send Transaction (v0 + Lookup table)',
        chainIds: [SupportedSolanaChainIds.SolanaMainnet],
        onClick: handleSignAndSendTransactionV0WithLookupTable,
      },
      {
        name: 'Sign Transaction',
        chainIds: [SupportedSolanaChainIds.SolanaMainnet],
        onClick: handleSignTransaction,
      },
      {
        name: 'Sign All Transactions',
        chainIds: [SupportedSolanaChainIds.SolanaMainnet],
        onClick: handleSignAllTransactions,
      },
      {
        name: 'Sign Message',
        chainIds: [SupportedSolanaChainIds.SolanaMainnet],
        onClick: handleSignMessage,
      },
      {
        name: 'Sign In',
        chainIds: [SupportedSolanaChainIds.SolanaMainnet],
        onClick: handleSignIn,
      },
      {
        name: 'Sign In Error',
        chainIds: [SupportedSolanaChainIds.SolanaMainnet],
        onClick: handleSignInError,
      },
      {
        name: 'Disconnect',
        chainIds: [SupportedSolanaChainIds.SolanaMainnet],
        onClick: handleDisconnect,
      },
    ];
  }, [
    handleSignAndSendTransaction,
    handleSignAndSendTransactionV0,
    handleSignAndSendTransactionV0WithLookupTable,
    handleSignTransaction,
    handleSignAllTransactions,
    handleSignMessage,
    handleSignIn,
    handleSignInError,
    handleDisconnect,
  ]);

  return (
    <AppWrapper>
      <Sidebar
        logsVisibility={logsVisibility}
        toggleLogs={toggleLogs}
        activePath="/sol-adapter-sandbox"
        topSection={<SolanaNetworkSelector network={network} setNetwork={handleNetworkSwitch} />}
      >
        {publicKey?.toBase58() && <ConnectedAs addresses={{ evm: null, solana: publicKey.toBase58() }} />}
        {!publicKey && <WalletMultiButton />}
        <ActionButtons connectedMethods={connectedMethods} connected={!!publicKey} />
        <CheckboxWithLabel
          dataTestId="auto-connect-checkbox"
          isEnabled={autoConnect}
          toggle={() => setAutoConnect((prev) => !prev)}
          label={'Use auto-connect'}
        />
      </Sidebar>
      {logsVisibility && <Logs connected={!!publicKey} logs={logs} clearLogs={clearLogs} />}
    </AppWrapper>
  );
};

// =============================================================================
// Main Component
// =============================================================================
const App = () => {
  const [network, setNetwork] = React.useState(SupportedSolanaChainIds.SolanaMainnet);
  const { autoConnect } = usePreferences();
  const createLog = useLogs().createLog;

  const endpoint = getConnectionUrl(network);

  const wallets = React.useMemo(
    () => [], // confirmed also with `() => []` for wallet-standard only
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [network]
  );

  return (
    <ConnectionProvider endpoint={endpoint}>
      <WalletProvider
        wallets={wallets}
        autoConnect={autoConnect}
        onError={(error) => {
          if (error instanceof WalletConnectionError) {
            createLog({
              status: 'error',
              method: 'connect',
              message: error.message,
            });
          }
        }}
      >
        <WalletModalProvider>
          <StatelessApp network={network} setNetwork={setNetwork} />
        </WalletModalProvider>
      </WalletProvider>
    </ConnectionProvider>
  );
};

const WrappedApp = () => (
  <PreferencesProvider>
    <LogsProvider>
      <App />
    </LogsProvider>
  </PreferencesProvider>
);

export default WrappedApp;
