export const getPermit2 = (
  chainId: string,
  value: string,
  verifyingContract: string,
  spender: string,
  token: string,
  expiration: string,
  nonce: string,
  sigDeadline: string
) => ({
  domain: { chainId: chainId, name: 'Permit2', verifyingContract },
  message: {
    details: {
      token,
      amount: value,
      expiration,
      nonce: '0',
    },
    spender,
    sigDeadline,
  },
  primaryType: 'PermitSingle',
  types: {
    EIP712Domain: [
      { name: 'name', type: 'string' },
      { name: 'chainId', type: 'uint256' },
      { name: 'verifyingContract', type: 'address' },
    ],
    PermitSingle: [
      { name: 'details', type: 'PermitDetails' },
      { name: 'spender', type: 'address' },
      { name: 'sigDeadline', type: 'uint256' },
    ],
    PermitDetails: [
      { name: 'token', type: 'address' },
      { name: 'amount', type: 'uint160' },
      { name: 'expiration', type: 'uint48' },
      { name: 'nonce', type: 'uint48' },
    ],
  },
});
