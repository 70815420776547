import { Connection } from '@solana/web3.js';
import { TLog } from '../../types';

const POLLING_INTERVAL = 1000; // one second
const MAX_POLLS = 30;

/**
 * Polls for transaction signature statuses
 * @param   {String}     signature  a transaction signature
 * @param   {Connection} connection an RPC connection
 * @param   {Function}   createLog  a function to create log
 * @returns
 */
const pollSolanaSignatureStatus = async (
  signatures: string[],
  connection: Connection,
  createLog: (log: TLog) => void,
  method: 'signAndSendTransaction' | 'signAndSendAllTransactions' = 'signAndSendTransaction'
): Promise<{
  signature: string;
  link: string;
}> => {
  let count = 0;

  return new Promise((resolve, reject) => {
    const interval = setInterval(async () => {
      // Failed to confirm transaction in time
      if (count === MAX_POLLS) {
        clearInterval(interval);
        createLog({
          status: 'error',
          method,
          message:
            method === 'signAndSendTransaction'
              ? `Transaction: ${signatures[0]}`
              : `Transactions: ${JSON.stringify(signatures)}`,
          messageTwo: `Failed to confirm transaction within ${MAX_POLLS} seconds. The transaction may or may not have succeeded.`,
        });
        return;
      }

      const { value } = await connection.getSignatureStatuses(signatures);
      const confirmationStatuses = value?.map(({ confirmationStatus }) => confirmationStatus);

      if (confirmationStatuses && confirmationStatuses.length > 0) {
        confirmationStatuses.forEach((confirmationStatus, index) => {
          if (confirmationStatus) {
            const hasReachedSufficientCommitment =
              confirmationStatus === 'confirmed' || confirmationStatus === 'finalized';
            const signature = signatures[index];

            createLog({
              status: hasReachedSufficientCommitment ? 'success' : 'info',
              method,
              message: `Transaction: ${signature}`,
              confirmation: {
                signature,
                link: `https://solscan.io/tx/${signature}`,
              },
              messageTwo: `Status: ${confirmationStatus.charAt(0).toUpperCase() + confirmationStatus.slice(1)}`,
            });

            if (hasReachedSufficientCommitment) {
              clearInterval(interval);
              resolve({
                signature,
                link: `https://solscan.io/tx/${signature}`,
              });
              return;
            }
          }
        });
      } else if (count === 0) {
        createLog({
          status: 'info',
          method,
          message:
            method === 'signAndSendTransaction'
              ? `Transaction: ${signatures[0]}`
              : `Transactions: ${JSON.stringify(signatures)}`,
          messageTwo: 'Status: Waiting on confirmation...',
        });
      }

      count++;
    }, POLLING_INTERVAL);
  });
};

export default pollSolanaSignatureStatus;
