// Provider with a boolean state to show/hide the dev settings
import React from 'react';
import { createContext, useContext, useState } from 'react';

export const DevContext = createContext({
  dev: false,
  toggleDev: () => {},
});

export const useDev = () => {
  const { dev, toggleDev } = useContext(DevContext);

  return { dev, toggleDev };
};

export const DevProvider = ({ children }) => {
  const [dev, setDev] = useState(false);

  const toggleDev = () => {
    setDev(!dev);
  };

  return <DevContext.Provider value={{ dev, toggleDev }}>{children}</DevContext.Provider>;
};
