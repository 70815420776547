import { SolanaSignInInput } from '@solana/wallet-standard-features';
import { PhantomSolanaProvider, ModifiedSolanaSignInOutput } from '../../types';
import { WalletContextState } from '@solana/wallet-adapter-react';

const signIn = async (
  signInData: SolanaSignInInput,
  provider?: PhantomSolanaProvider,
  walletContext?: WalletContextState
): Promise<ModifiedSolanaSignInOutput> => {
  try {
    if (provider) return await provider.signIn(signInData);
    if (walletContext) return await walletContext.signIn(signInData);
    throw new Error('No provider or wallet context found');
  } catch (error) {
    console.warn(error);
    throw new Error(error.message);
  }
};

export default signIn;
