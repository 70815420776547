/**
 * @DEV: If the sandbox is throwing dependency errors, chances are you need to clear your browser history.
 * This will trigger a re-install of the dependencies in the sandbox – which should fix things right up.
 * Alternatively, you can fork this sandbox to refresh the dependencies manually.
 */
import React, { useEffect } from 'react';

import Sidebar from '../../components/Sidebar';

import { useWeb3React, Web3ReactProvider } from '@web3-react/core';
import { Web3Provider } from '@ethersproject/providers';
import { PhantomConnector } from 'web3-react-v6-phantom';
import { Signer } from 'ethers';
import { parseUnits } from 'ethers/lib/utils';
import { ActionButtons } from '../../components/Sidebar/ActionButtons';
import Button from '../../components/Button';
import { SupportedEVMChainIds } from '../../constants/chains';
import { LogsProvider, useLogs } from '../../hooks/useLogs';
import { Logs } from '../../components/Logs';
import { AppWrapper } from '../../components/AppWrapper';
import { TestId } from '../../components/TestId';
import { ConnectedAs } from '../../components/Sidebar/ConnectedAs';

const phantom = new PhantomConnector({
  supportedChainIds: [1, 5],
});

// =============================================================================
// Constants
// =============================================================================

const message = 'To avoid digital dognappers, sign below to authenticate with CryptoCorgis.';
const tx = {
  to: '0x0000000000000000000000000000000000000000',
  value: parseUnits('1', 'wei'),
  chainId: 0x5, // Goerli network
};

// =============================================================================
// App Component
// =============================================================================

const AppComponent = () => {
  const { logs, clearLogs, createLog, logsVisibility, toggleLogs } = useLogs();
  const { library, activate, deactivate } = useWeb3React();

  const [address, setAddress] = React.useState<string | null>(null);

  useEffect(() => {
    if (library) {
      library.getSigner().getAddress().then(setAddress);
    }
  }, [library]);

  const handleDisconnect = () => {
    try {
      deactivate();
      setAddress(null);
      createLog({
        status: 'warning',
        method: 'disconnect',
        message: 'User disconnected wallet',
      });
    } catch (e) {
      createLog({
        status: 'error',
        method: 'disconnect',
        message: e.message,
      });
      console.error(e);
    }
  };

  const handleSignMessage = async () => {
    try {
      const signer = await library.getSigner();
      const sig = await signer.signMessage(message);
      createLog({
        status: 'success',
        method: 'signMessage',
        message: `Message signed: ${sig}`,
      });
    } catch (e) {
      createLog({
        status: 'error',
        method: 'signMessage',
        message: e.message,
      });
    }
  };

  const handleConnect = async () => {
    try {
      await activate(phantom);
      createLog({
        status: 'success',
        method: 'connect',
        message: `Connected to app!`,
      });
    } catch (e) {
      createLog({
        status: 'error',
        method: 'connect',
        message: e.message,
      });
    }
  };

  const handleTransaction = async () => {
    try {
      const signer: Signer = await library.getSigner();
      const pendingHash = await signer.sendTransaction(tx);
      createLog({
        status: 'info',
        method: 'eth_sendTransaction',
        message: `Sending TX: ${pendingHash.hash}`,
      });
      createLog({
        status: 'info',
        method: 'eth_sendTransaction',
        message: `Pending....this could take up to 30 seconds`,
      });
      const finalizedHash = await pendingHash.wait(1);
      createLog({
        status: 'success',
        method: 'eth_sendTransaction',
        message: `Successfully burned 1 wei of ETH ${finalizedHash.blockHash}`,
      });
    } catch (e) {
      createLog({
        status: 'error',
        method: 'eth_sendTransaction',
        message: e.message,
      });
    }
  };

  const connectedMethods = [
    {
      name: 'Deactivate',
      chainIds: [SupportedEVMChainIds.EthereumMainnet],
      onClick: handleDisconnect,
    },
    {
      name: 'Sign Message',
      chainIds: [SupportedEVMChainIds.EthereumMainnet],
      onClick: handleSignMessage,
    },
    {
      name: 'Send Transaction (Burn 1 wei on Goerli)',
      chainIds: [SupportedEVMChainIds.EthereumMainnet],
      onClick: handleTransaction,
    },
  ];

  return (
    <AppWrapper>
      <Sidebar logsVisibility={logsVisibility} toggleLogs={toggleLogs} activePath="/web3-react-v6-sandbox">
        {!address && (
          <div>
            <Button onClick={handleConnect} data-testid="connect">
              Connect to Phantom
            </Button>
            <TestId id="connect" />
          </div>
        )}
        {address && <ConnectedAs addresses={{ evm: address, solana: null }} />}
        <ActionButtons connected={!!address} connectedMethods={connectedMethods} />
      </Sidebar>
      {logsVisibility && <Logs connected={!!address} logs={logs} clearLogs={clearLogs} />}
    </AppWrapper>
  );
};

// =============================================================================
// Main Component
// =============================================================================

const App = () => {
  function getLibrary(provider: any): Web3Provider {
    const library = new Web3Provider(provider);
    library.pollingInterval = 12000;
    return library;
  }

  return (
    <LogsProvider>
      <Web3ReactProvider getLibrary={getLibrary}>
        <AppComponent />
      </Web3ReactProvider>
    </LogsProvider>
  );
};

export default App;
