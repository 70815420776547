import { TransactionResponse, Web3Provider } from '@ethersproject/providers';
import { utils } from 'ethers';
import { ERC721_TOKENS } from '../../constants/tokens';

/**
 * Sends 1 wei to yourself
 * @param {Web3Provider} provider a web3 provider object
 * @returns {Promise<TransactionResponse>} a raw transaction object (hasn't been confirmed by network)
 */
async function mintNFT(chainId: string, provider: Web3Provider): Promise<TransactionResponse> {
  try {
    const signer = provider.getSigner();
    const address = await signer.getAddress();
    const gasPrice = await provider.getGasPrice();
    const tokenAddress = ERC721_TOKENS[chainId];

    if (!tokenAddress) {
      throw new Error('Token address not found');
    }

    // keccak256("mint()").slice(0, 10) -> first 4 bytes of the function signature
    const mintFunctionSignature = utils.id('mintFree()').slice(0, 10);

    const transactionParameters = {
      gasPrice, // customizable by user during confirmation.
      // gasLimit: utils.hexlify(100000),
      to: tokenAddress, // Required except during contract publications.
      from: address,
      value: 0, // Only required to send ether to the recipient from the initiating external account.
      data: mintFunctionSignature, // Call to mint() function
      chainId: parseInt(chainId),
    };

    return signer.sendTransaction(transactionParameters);
  } catch (error) {
    console.warn(error);
    throw new Error(error.message);
  }
}

export default mintNFT;
