/**
 * @DEV: If the sandbox is throwing dependency errors, chances are you need to clear your browser history.
 * This will trigger a re-install of the dependencies in the sandbox – which should fix things right up.
 * Alternatively, you can fork this sandbox to refresh the dependencies manually.
 */
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Connection, PublicKey } from '@solana/web3.js';

import {
  createAddressLookupTable,
  createSignInData,
  createSignInErrorData,
  createTransferTransaction,
  createTransferTransactionV0,
  extendAddressLookupTable,
  getProvider,
  signAllTransactions,
  signAndSendTransaction,
  signAndSendTransactionV0WithLookupTable,
  signIn,
  signMessage,
  signTransaction,
  pollSolanaSignatureStatus,
  signAndSendAllTransactions,
} from '../../utils/sol';

import { PhantomSolanaProvider } from '../../types';

import Sidebar from '../../components/Sidebar';

import { toast } from 'react-toastify';
import { ActionButtons } from '../../components/Sidebar/ActionButtons';
import { ConnectedMethods, TLog } from '../../types';
import { SupportedSolanaChainIds } from '../../constants/chains';
import { SolanaNetworkSelector } from '../../components/NetworkSelector';
import Button from '../../components/Button';
import { LogsProvider, useLogs } from '../../hooks/useLogs';
import { Logs } from '../../components/Logs';
import { AppWrapper } from '../../components/AppWrapper';
import { TestId } from '../../components/TestId';
import { ConnectedAs } from '../../components/Sidebar/ConnectedAs';
import styled from 'styled-components';
import { CheckboxWithLabel } from '../../components/CheckboxWithLabel';
import { PreferencesProvider, usePreferences } from '../../hooks/usePreferences';

// =============================================================================
// Constants
// =============================================================================

const message = 'To avoid digital dognappers, sign below to authenticate with CryptoCorgis.';
const sleep = (timeInMS) => new Promise((resolve) => setTimeout(resolve, timeInMS));

const getConnectionUrl = (network: string): string => {
  switch (network) {
    case SupportedSolanaChainIds.SolanaDevnet:
      // NB: This URL will only work for Phantom sandbox apps! Please do not use this for your project.
      return process.env.REACT_APP_SOLANA_DEVNET_RPC;
    case SupportedSolanaChainIds.SolanaMainnet:
      // NB: This URL will only work for Phantom sandbox apps! Please do not use this for your project.
      return process.env.REACT_APP_SOLANA_MAINNET_RPC;
    default:
      throw new Error(`Invalid network: ${network}`);
  }
};

// =============================================================================
// Typedefs
// =============================================================================

interface Props {
  publicKey: PublicKey | null;
  connectedMethods: ConnectedMethods[];
  handleConnect: () => Promise<void>;
  handleSignIn: () => Promise<void>;
  logs: TLog[];
  clearLogs: () => void;
  toggleLogs: () => void;
  logsVisibility: boolean;
  handleNetworkSwitch: (newNetwork: string) => Promise<void>;
  network: string;
  logAutoConnectErrors: boolean;
  toggleLogAutoConnectErrors: () => void;
  onlyIfTrusted: boolean;
  toggleOnlyIfTrusted: () => void;
}

// =============================================================================
// Styles
// =============================================================================

const ConnectActions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
`;

// =============================================================================
// Hooks
// =============================================================================

/**
 * @DEVELOPERS
 * The fun stuff!
 */
const useProps = (): Props => {
  const [provider, setProvider] = useState<PhantomSolanaProvider | null>(null);
  const [network, setNetwork] = useState<string>(SupportedSolanaChainIds.SolanaMainnet);
  const [connection, setConnection] = useState(new Connection(getConnectionUrl(network)));
  const { logs, createLog, clearLogs, toggleLogs, logsVisibility } = useLogs();
  const { logAutoConnectErrors, onlyIfTrusted, setLogAutoConnectErrors, setOnlyIfTrusted } = usePreferences();

  useEffect(() => {
    (async () => {
      // sleep for 100 ms to give time to inject
      await sleep(100);
      setProvider(getProvider());
    })();
  }, []);

  useEffect(() => {
    if (!provider) return;

    // attempt to eagerly connect
    provider.connect({ onlyIfTrusted }).catch((error) => {
      if (logAutoConnectErrors) {
        createLog({
          status: 'error',
          method: 'connect',
          message: `Eager connect failed: ${error.message}`,
        });
      }
    });

    provider.on('connect', (publicKey: PublicKey) => {
      createLog({
        status: 'success',
        method: 'connect',
        message: `Connected to account ${publicKey.toBase58()}`,
      });
    });

    provider.on('disconnect', () => {
      createLog({
        status: 'warning',
        method: 'disconnect',
        message: '👋',
      });
    });

    provider.on('accountChanged', (publicKey: PublicKey | null) => {
      if (publicKey) {
        createLog({
          status: 'info',
          method: 'accountChanged',
          message: `Switched to account ${publicKey.toBase58()}`,
        });
      } else {
        /**
         * In this case dApps could...
         *
         * 1. Not do anything
         * 2. Only re-connect to the new account if it is trusted
         *
         * ```
         * provider.connect({ onlyIfTrusted: true }).catch((err) => {
         *  // fail silently
         * });
         * ```
         *
         * 3. Always attempt to reconnect
         */

        createLog({
          status: 'info',
          method: 'accountChanged',
          message: 'Attempting to switch accounts.',
        });

        provider.connect().catch((error) => {
          createLog({
            status: 'error',
            method: 'accountChanged',
            message: `Failed to re-connect: ${error.message}`,
          });
        });
      }
    });

    return () => {
      provider.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createLog, provider]);

  /** SignAndSendTransaction */
  const handleSignAndSendTransaction = useCallback(async () => {
    if (!provider) return;

    try {
      const transaction = await createTransferTransaction(provider.publicKey, connection);
      createLog({
        status: 'info',
        method: 'signAndSendTransaction',
        message: `Requesting signature for: ${JSON.stringify(transaction)}`,
      });
      const signature = await signAndSendTransaction(transaction, provider);
      createLog({
        status: 'info',
        method: 'signAndSendTransaction',
        message: `Signed and submitted transaction ${signature}.`,
      });
      pollSolanaSignatureStatus([signature], connection, createLog).then(() => {
        toast.success('Transaction confirmed');
      });
    } catch (error) {
      createLog({
        status: 'error',
        method: 'signAndSendTransaction',
        message: error.message,
      });
      toast.error("Transaction couldn't be confirmed");
    }
  }, [createLog, provider, connection]);

  /** SignAndSendTransactionV0 */
  const handleSignAndSendTransactionV0 = useCallback(async () => {
    if (!provider) return;

    try {
      const transactionV0 = await createTransferTransactionV0(provider.publicKey, connection);
      createLog({
        status: 'info',
        method: 'signAndSendTransactionV0',
        message: `Requesting signature for: ${JSON.stringify(transactionV0)}`,
      });
      const signature = await signAndSendTransaction(transactionV0, provider);
      createLog({
        status: 'info',
        method: 'signAndSendTransactionV0',
        message: `Signed and submitted transactionV0 ${signature}.`,
      });
      pollSolanaSignatureStatus([signature], connection, createLog).then(() => {
        toast.success('Transaction confirmed');
      });
    } catch (error) {
      toast.error("Transaction couldn't be confirmed");
      createLog({
        status: 'error',
        method: 'signAndSendTransactionV0',
        message: error.message,
      });
    }
  }, [createLog, provider, connection]);

  /** SignTransaction */
  const handleSignTransaction = useCallback(async () => {
    if (!provider) return;

    try {
      const transaction = await createTransferTransaction(provider.publicKey, connection);
      createLog({
        status: 'info',
        method: 'signTransaction',
        message: `Requesting signature for: ${JSON.stringify(transaction)}`,
      });
      const signedTransaction = await signTransaction(transaction, provider);
      createLog({
        status: 'success',
        method: 'signTransaction',
        message: `Transaction signed: ${JSON.stringify(signedTransaction)}`,
      });
      toast.success('Transaction signed');
    } catch (error) {
      toast.error("Transaction couldn't be signed");
      createLog({
        status: 'error',
        method: 'signTransaction',
        message: error.message,
      });
    }
  }, [createLog, provider, connection]);

  /** SignAllTransactions */
  const handleSignAllTransactions = useCallback(
    async (numTransactions: number) => {
      if (!provider) return;

      try {
        const transactions = [];

        for (let i = 0; i < numTransactions; i++) {
          const transaction = await createTransferTransaction(provider.publicKey, connection);
          transactions.push(transaction);
        }
        createLog({
          status: 'info',
          method: 'signAllTransactions',
          message: `Requesting signature for: ${JSON.stringify(transactions)}`,
        });
        const signedTransactions = await signAllTransactions(transactions, provider);
        createLog({
          status: 'success',
          method: 'signAllTransactions',
          message: `Transactions signed: ${JSON.stringify(signedTransactions)}`,
        });
        toast.success('Transactions signed');
      } catch (error) {
        toast.error("Transactions couldn't be signed");
        createLog({
          status: 'error',
          method: 'signAllTransactions',
          message: error.message,
        });
      }
    },
    [createLog, provider, connection]
  );

  /** SignAndSendAllTransactions */
  const handleSignAndSendAllTransactions = useCallback(
    async (numTransactions: number) => {
      if (!provider) return;

      try {
        const transactions = [];

        for (let i = 0; i < numTransactions; i++) {
          const transaction = await createTransferTransaction(provider.publicKey, connection);
          transactions.push(transaction);
        }
        createLog({
          status: 'info',
          method: 'signAndSendAllTransactions',
          message: `Requesting signatures for: ${JSON.stringify(transactions)}`,
        });
        const { publicKey, signatures } = await signAndSendAllTransactions(transactions, provider);
        createLog({
          status: 'info',
          method: 'signAndSendAllTransactions',
          message: `Signed and submitted transactions: ${JSON.stringify({ publicKey, signatures })}.`,
        });
        Promise.all(
          signatures.map((signature) =>
            pollSolanaSignatureStatus([signature], connection, createLog, 'signAndSendAllTransactions')
          )
        ).then(() => {
          toast.success('All transactions confirmed');
        });
      } catch (error) {
        createLog({
          status: 'error',
          method: 'signAndSendAllTransactions',
          message: error.message,
        });
        toast.error("Transactions couldn't be confirmed");
      }
    },
    [createLog, provider, connection]
  );

  /** SignMessage */
  const handleSignMessage = useCallback(async () => {
    if (!provider) return;

    try {
      const signedMessage = await signMessage(message, provider);
      createLog({
        status: 'success',
        method: 'signMessage',
        message: `Message signed: ${JSON.stringify(signedMessage)}`,
      });
      return signedMessage;
    } catch (error) {
      createLog({
        status: 'error',
        method: 'signMessage',
        message: error.message,
      });
    }
  }, [createLog, provider]);

  /** Connect */
  const handleConnect = useCallback(async () => {
    if (!provider) return;

    try {
      await provider.connect();
    } catch (error) {
      createLog({
        status: 'error',
        method: 'connect',
        message: error.message,
      });
    }
  }, [createLog, provider]);

  /** SignIn */
  const handleSignIn = useCallback(async () => {
    if (!provider) return;
    const signInData = await createSignInData();

    try {
      const { address, account, signedMessage, signature } = await signIn(signInData, provider);
      const message = new TextDecoder().decode(signedMessage);
      const decodedSignature = new TextDecoder().decode(signature);
      createLog({
        status: 'success',
        method: 'signIn',
        message: `Message signed: ${message} by ${address || account.address} with signature ${decodedSignature}`,
      });
    } catch (error) {
      createLog({
        status: 'error',
        method: 'signIn',
        message: error.message,
      });
    }
  }, [createLog, provider]);

  /** SignInError */
  const handleSignInError = useCallback(async () => {
    if (!provider) return;
    const signInData = await createSignInErrorData();

    try {
      const { address, account, signedMessage, signature } = await signIn(signInData, provider);
      const message = new TextDecoder().decode(signedMessage);
      const decodedSignature = new TextDecoder().decode(signature);
      createLog({
        status: 'success',
        method: 'signMessage',
        message: `Message signed: ${message} by ${address || account.address} with signature ${decodedSignature}`,
      });
    } catch (error) {
      createLog({
        status: 'error',
        method: 'signIn',
        message: error.message,
      });
    }
  }, [createLog, provider]);

  /** Disconnect */
  const handleDisconnect = useCallback(async () => {
    if (!provider) return;

    try {
      await provider.disconnect();
    } catch (error) {
      createLog({
        status: 'error',
        method: 'disconnect',
        message: error.message,
      });
    }
  }, [createLog, provider]);

  /** Network Switch */
  const handleNetworkSwitch = useCallback(
    async (newNetwork: string) => {
      try {
        const newConnection = new Connection(getConnectionUrl(newNetwork), 'confirmed');
        setConnection(newConnection);
        setNetwork(newNetwork);
        createLog({
          status: 'success',
          message: `Switched to ${newNetwork} network`,
        });
      } catch (error) {
        console.log('catch error');
        createLog({
          status: 'error',
          message: error.message,
        });
      }
    },
    [createLog, setConnection, setNetwork]
  );

  /** Mild Spam Sign Message */
  const handleMildSpamSignMessage = useCallback(async () => {
    // Call sign message 4 times
    for (let i = 0; i < 4; i++) {
      await handleSignMessage();
    }
  }, [handleSignMessage]);

  /** Extreme Spam Sign Message */
  const handleExtremeSpamSignMessage = useCallback(async () => {
    // Call sign message 20 times
    for (let i = 0; i < 20; i++) {
      await handleSignMessage();
    }
  }, [handleSignMessage]);

  /** Extreme Spam Sign Transaction */
  const handleExtremeSpamSignTransaction = useCallback(async () => {
    // Call sign transaction 20 times
    for (let i = 0; i < 20; i++) {
      await handleSignTransaction();
    }
  }, [handleSignTransaction]);

  const connectedMethods = useMemo(() => {
    return [
      {
        name: 'Sign and Send Transaction (Legacy)',
        chainIds: [SupportedSolanaChainIds.SolanaMainnet],
        onClick: handleSignAndSendTransaction,
      },
      {
        name: 'Sign and Send Transaction (v0)',
        chainIds: [SupportedSolanaChainIds.SolanaMainnet],
        onClick: handleSignAndSendTransactionV0,
      },
      {
        name: 'Sign and Send All Transactions',
        chainIds: [SupportedSolanaChainIds.SolanaMainnet],
        onClick: () => handleSignAndSendAllTransactions(2),
      },
      {
        name: 'Sign Transaction',
        chainIds: [SupportedSolanaChainIds.SolanaMainnet],
        onClick: handleSignTransaction,
      },
      {
        name: 'Sign All Transactions',
        chainIds: [SupportedSolanaChainIds.SolanaMainnet],
        onClick: () => handleSignAllTransactions(2),
      },
      {
        name: 'Sign Message',
        chainIds: [SupportedSolanaChainIds.SolanaMainnet],
        onClick: handleSignMessage,
      },
      {
        name: 'Sign In',
        chainIds: [SupportedSolanaChainIds.SolanaMainnet],
        onClick: handleSignIn,
      },
      {
        name: 'Sign In Error',
        chainIds: [SupportedSolanaChainIds.SolanaMainnet],
        onClick: handleSignInError,
      },
      {
        name: 'Spam Sign Message (4x)',
        chainIds: [SupportedSolanaChainIds.SolanaMainnet],
        onClick: handleMildSpamSignMessage,
      },
      {
        name: 'Spam Sign Message (20x)',
        chainIds: [SupportedSolanaChainIds.SolanaMainnet],
        onClick: handleExtremeSpamSignMessage,
      },
      {
        name: 'Spam Sign Transaction (20x)',
        chainIds: [SupportedSolanaChainIds.SolanaMainnet],
        onClick: handleExtremeSpamSignTransaction,
      },
      {
        name: 'Sign 200 transactions at the same time',
        chainIds: [SupportedSolanaChainIds.SolanaMainnet],
        onClick: () => handleSignAllTransactions(200),
      },
      {
        name: 'Disconnect',
        chainIds: [SupportedSolanaChainIds.SolanaMainnet],
        onClick: handleDisconnect,
      },
    ];
  }, [
    handleSignAndSendTransaction,
    handleSignAndSendTransactionV0,
    handleSignAndSendAllTransactions,
    handleSignTransaction,
    handleSignMessage,
    handleSignIn,
    handleSignInError,
    handleMildSpamSignMessage,
    handleExtremeSpamSignMessage,
    handleExtremeSpamSignTransaction,
    handleDisconnect,
    handleSignAllTransactions,
  ]);

  return {
    publicKey: provider?.publicKey || null,
    connectedMethods,
    handleConnect,
    handleSignIn,
    logs,
    clearLogs,
    toggleLogs,
    logsVisibility,
    handleNetworkSwitch,
    network,
    logAutoConnectErrors,
    toggleLogAutoConnectErrors: () => setLogAutoConnectErrors((prev) => !prev),
    onlyIfTrusted,
    toggleOnlyIfTrusted: () => setOnlyIfTrusted((prev) => !prev),
  };
};

// =============================================================================
// Stateless Component
// =============================================================================

const App = () => {
  const {
    publicKey,
    connectedMethods,
    handleConnect,
    handleSignIn,
    logs,
    clearLogs,
    logsVisibility,
    toggleLogs,
    handleNetworkSwitch,
    network,
    logAutoConnectErrors,
    toggleLogAutoConnectErrors,
    onlyIfTrusted,
    toggleOnlyIfTrusted,
  } = useProps();

  return (
    <AppWrapper>
      <Sidebar
        logsVisibility={logsVisibility}
        toggleLogs={toggleLogs}
        topSection={<SolanaNetworkSelector network={network} setNetwork={handleNetworkSwitch} />}
        activePath="/sol-sandbox"
      >
        {publicKey && <ConnectedAs addresses={{ evm: null, solana: publicKey.toBase58() }} />}
        {!publicKey && (
          <ConnectActions>
            <div>
              <Button onClick={handleConnect} data-testid="connect">
                Connect to Phantom
              </Button>
              <TestId id="connect" />
            </div>
            <div>
              <Button onClick={handleSignIn} data-testid="signIn">
                Sign Into Phantom
              </Button>
              <TestId id="signIn" />
            </div>
          </ConnectActions>
        )}
        <ActionButtons connectedMethods={connectedMethods} connected={!!publicKey} />
        <CheckboxWithLabel
          dataTestId="only-if-trusted-checkbox"
          isEnabled={onlyIfTrusted}
          toggle={toggleOnlyIfTrusted}
          label={'Use onlyIfTrusted with connect'}
        />
        <CheckboxWithLabel
          dataTestId="log-eager-connect-checkbox"
          isEnabled={logAutoConnectErrors}
          toggle={toggleLogAutoConnectErrors}
          label={'Show eager connect error logs'}
        />
      </Sidebar>
      {logsVisibility && <Logs connected={!!publicKey} logs={logs} clearLogs={clearLogs} />}
    </AppWrapper>
  );
};

const WrappedApp = () => (
  <PreferencesProvider>
    <LogsProvider>
      <App />
    </LogsProvider>
  </PreferencesProvider>
);

export default WrappedApp;
