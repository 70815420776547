import { Transaction, SystemProgram, Connection, PublicKey } from '@solana/web3.js';

const createTransferTransaction = async (
  publicKey: PublicKey,
  connection: Connection,
  lamports?: number
): Promise<Transaction> => {
  const transaction = new Transaction().add(
    SystemProgram.transfer({
      fromPubkey: publicKey,
      toPubkey: publicKey,
      lamports: lamports || 100,
    })
  );
  transaction.feePayer = publicKey;
  transaction.recentBlockhash = (await connection.getLatestBlockhash()).blockhash;

  return transaction;
};

export default createTransferTransaction;
