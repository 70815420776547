import { PhantomSolanaProvider } from '../../types';

const getProvider = (): PhantomSolanaProvider | undefined => {
  if ('phantom' in window) {
    const anyWindow: any = window;
    const provider = anyWindow.phantom?.solana;

    if (provider?.isPhantom) {
      return provider;
    }
  }
};

export default getProvider;
