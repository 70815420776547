import { Transaction, VersionedTransaction } from '@solana/web3.js';
import { PhantomSolanaProvider } from '../../types';
import { WalletContextState } from '@solana/wallet-adapter-react';

const signAllTransactions = async (
  transactions: (Transaction | VersionedTransaction)[],
  provider?: PhantomSolanaProvider,
  walletContext?: WalletContextState
): Promise<(Transaction | VersionedTransaction)[]> => {
  try {
    if (provider) return await provider.signAllTransactions(transactions);
    if (walletContext) return await walletContext.signAllTransactions(transactions);
    throw new Error('No provider or wallet context found');
  } catch (error) {
    console.warn(error);
    throw new Error(error.message);
  }
};

export default signAllTransactions;
