/**
 * @DEV: If the sandbox is throwing dependency errors, chances are you need to clear your browser history.
 * This will trigger a re-install of the dependencies in the sandbox – which should fix things right up.
 * Alternatively, you can fork this sandbox to refresh the dependencies manually.
 */
import React, { useEffect, useMemo, useRef } from 'react';
import '@rainbow-me/rainbowkit/styles.css';
import { getDefaultConfig, RainbowKitProvider, darkTheme, ConnectButton } from '@rainbow-me/rainbowkit';
import { phantomWallet, injectedWallet } from '@rainbow-me/rainbowkit/wallets';
import { WagmiProvider, useAccount, useSendTransaction, useSignMessage } from 'wagmi';
import { mainnet, polygon, polygonAmoy, sepolia } from 'wagmi/chains';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';

import { parseGwei } from 'viem';

import Sidebar from '../../components/Sidebar';
import { ActionButtons } from '../../components/Sidebar/ActionButtons';
import { SupportedEVMChainIds } from '../../constants/chains';
import { LogsProvider, useLogs } from '../../hooks/useLogs';
import { Logs } from '../../components/Logs';
import { AppWrapper } from '../../components/AppWrapper';
import { ConnectedAs } from '../../components/Sidebar/ConnectedAs';

// =============================================================================
// Rainbowkit Configuration
// =============================================================================

const queryClient = new QueryClient();

const config = getDefaultConfig({
  appName: 'Phantom Sandbox',
  projectId: 'sandbox',
  chains: [mainnet, polygon, polygonAmoy, sepolia],
  wallets: [
    {
      groupName: 'The Best',
      wallets: [phantomWallet, injectedWallet],
    },
  ],
  ssr: true, // If your dApp uses server side rendering (SSR)
});

// =============================================================================
// Constants
// =============================================================================

const MESSAGE = 'To avoid digital dognappers, sign below to authenticate with CryptoCorgis.';
//0x0000...000 common for burning ETH

// =============================================================================
// Stateless Component
// =============================================================================

const AppComponent = () => {
  const { logs, createLog, clearLogs, toggleLogs, logsVisibility } = useLogs();

  const { address, status } = useAccount();

  let prevStatus = useRef(status);
  useEffect(() => {
    switch (status) {
      case 'disconnected':
        if (prevStatus.current === 'disconnected') break;
        createLog({
          status: 'warning',
          method: 'disconnect',
          message: 'user disconnected wallet',
        });
        break;
      case 'connected':
        createLog({
          status: 'success',
          method: 'connect',
          message: `Connected to app with account ${address}}`,
        });
        break;
      case 'connecting':
        createLog({
          status: 'info',
          method: 'connect',
          message: 'user connecting...',
        });
        break;
    }
    prevStatus.current = status;
  }, [createLog, address, status]);

  const { signMessage } = useSignMessage();

  const { sendTransaction } = useSendTransaction();

  const connectedMethods = useMemo(() => {
    return [
      {
        name: 'Sign Message',
        chainIds: [SupportedEVMChainIds.EthereumMainnet],
        onClick: () =>
          signMessage(
            {
              message: MESSAGE,
              account: address,
            },
            {
              onSettled: (data, error) => {
                if (error) {
                  createLog({
                    status: 'error',
                    method: 'signMessage',
                    message: error.message,
                  });
                  return;
                }
                createLog({
                  status: 'success',
                  method: 'signMessage',
                  message: `Message signed: ${data}`,
                });
              },
            }
          ),
      },
      {
        name: 'Send Transaction (burn 1 wei on Goerli)',
        chainIds: [SupportedEVMChainIds.EthereumSepolia],
        onClick: () =>
          sendTransaction(
            {
              to: '0x0000000000000000000000000000000000000000', // Common for burning ETH
              value: parseGwei('1', 'wei'),
            },
            {
              onSettled: (data, error) => {
                if (error) {
                  createLog({
                    status: 'error',
                    method: 'eth_sendTransaction',
                    message: `Error occurred: ${error.message}`,
                  });
                  return;
                }
                createLog({
                  status: 'success',
                  method: 'eth_sendTransaction',
                  message: `Transaction success: ${data}`,
                });
              },
            }
          ),
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signMessage, sendTransaction]);

  return (
    <AppWrapper>
      <Sidebar logsVisibility={logsVisibility} toggleLogs={toggleLogs} activePath="/rainbowkit-sandbox">
        {!!address && <ConnectedAs addresses={{ evm: address, solana: null }} />}
        <div style={{ margin: '15px' }}>
          <ConnectButton />
        </div>
        <ActionButtons connectedMethods={connectedMethods} connected={!!address} />
      </Sidebar>

      {logsVisibility && <Logs connected={!!address} logs={logs} clearLogs={clearLogs} />}
    </AppWrapper>
  );
};

// =============================================================================
// Main Component
// =============================================================================

const App = () => {
  return (
    <LogsProvider>
      <WagmiProvider config={config}>
        <QueryClientProvider client={queryClient}>
          <RainbowKitProvider theme={darkTheme()}>
            <AppComponent />
          </RainbowKitProvider>
        </QueryClientProvider>
      </WagmiProvider>
    </LogsProvider>
  );
};

export default App;
