import { Transaction, VersionedTransaction } from '@solana/web3.js';
import { PhantomSolanaProvider } from '../../types';

const signAndSendAllTransactions = async (
  transactions: (Transaction | VersionedTransaction)[],
  provider: PhantomSolanaProvider
): Promise<{ publicKey: string; signatures: (string | null)[] }> => {
  try {
    return await provider.signAndSendAllTransactions(transactions);
  } catch (error) {
    console.warn(error);
    throw new Error(error.message);
  }
};

export default signAndSendAllTransactions;
