import {
  AddressLookupTableProgram,
  Connection,
  PublicKey,
  TransactionMessage,
  VersionedTransaction,
} from '@solana/web3.js';

import { PhantomSolanaProvider } from '../../types';
import { signAndSendTransaction } from '.';
import { WalletContextState } from '@solana/wallet-adapter-react';

const createAddressLookupTable = async (
  publicKey: PublicKey,
  connection: Connection,
  blockhash: string,
  provider?: PhantomSolanaProvider,
  walletContext?: WalletContextState
): Promise<[string, PublicKey]> => {
  // get current `slot`
  let slot = await connection.getSlot();

  // create an Address Lookup Table
  const [lookupTableInst, lookupTableAddress] = AddressLookupTableProgram.createLookupTable({
    authority: publicKey,
    payer: publicKey,
    recentSlot: slot,
  });

  console.log('lookup table address:', lookupTableAddress.toBase58());

  // To create the Address Lookup Table on chain:
  // send the `lookupTableInst` instruction in a transaction
  const lookupMessage = new TransactionMessage({
    payerKey: publicKey,
    recentBlockhash: blockhash,
    instructions: [lookupTableInst],
  }).compileToV0Message();

  const lookupTransaction = new VersionedTransaction(lookupMessage);
  const lookupSignature = await signAndSendTransaction(lookupTransaction, provider, walletContext, connection);
  console.log('Sent transaction for lookup table:', lookupSignature);

  return [lookupSignature, lookupTableAddress];
};

export default createAddressLookupTable;
