/**
 * @DEV: If the sandbox is throwing dependency errors, chances are you need to clear your browser history.
 * This will trigger a re-install of the dependencies in the sandbox – which should fix things right up.
 * Alternatively, you can fork this sandbox to refresh the dependencies manually.
 */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Connection, PublicKey } from '@solana/web3.js';

import {
  createAddressLookupTable,
  createSignInData,
  createSignInErrorData,
  extendAddressLookupTable,
  getProvider,
  pollSolanaSignatureStatus,
  signAndSendTransactionV0WithLookupTable,
  signIn,
} from '../../utils/sol';

import { ConnectedMethods, PhantomSolanaProvider, TLog } from '../../types';

import Sidebar from '../../components/Sidebar';

import { toast } from 'react-toastify';
import { ActionButtons } from '../../components/Sidebar/ActionButtons';
import { SupportedSolanaChainIds } from '../../constants/chains';
import { SolanaNetworkSelector } from '../../components/NetworkSelector';
import Button from '../../components/Button';
import { LogsProvider, useLogs } from '../../hooks/useLogs';
import { Logs } from '../../components/Logs';
import { AppWrapper } from '../../components/AppWrapper';
import { TestId } from '../../components/TestId';
import { ConnectedAs } from '../../components/Sidebar/ConnectedAs';
import styled from 'styled-components';
import { CheckboxWithLabel } from '../../components/CheckboxWithLabel';
import { PreferencesProvider, usePreferences } from '../../hooks/usePreferences';

// =============================================================================
// Constants
// =============================================================================

const message = 'To avoid digital dognappers, sign below to authenticate with CryptoCorgis.';
const sleep = (timeInMS) => new Promise((resolve) => setTimeout(resolve, timeInMS));

const getConnectionUrl = (network: string): string => {
  switch (network) {
    case SupportedSolanaChainIds.SolanaDevnet:
      // NB: This URL will only work for Phantom sandbox apps! Please do not use this for your project.
      return process.env.REACT_APP_SOLANA_DEVNET_RPC;
    case SupportedSolanaChainIds.SolanaMainnet:
      // NB: This URL will only work for Phantom sandbox apps! Please do not use this for your project.
      return process.env.REACT_APP_SOLANA_MAINNET_RPC;
    default:
      throw new Error(`Invalid network: ${network}`);
  }
};

// =============================================================================
// Typedefs
// =============================================================================

interface Props {
  publicKey: PublicKey | null;
  connectedMethods: ConnectedMethods[];
  handleConnect: () => Promise<void>;
  handleSignIn: () => Promise<void>;
  logs: TLog[];
  clearLogs: () => void;
  toggleLogs: () => void;
  logsVisibility: boolean;
  handleNetworkSwitch: (newNetwork: string) => Promise<void>;
  network: string;
  logAutoConnectErrors: boolean;
  toggleLogAutoConnectErrors: () => void;
  onlyIfTrusted: boolean;
  toggleOnlyIfTrusted: () => void;
}

// =============================================================================
// Styles
// =============================================================================

const ConnectActions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
`;

// =============================================================================
// Hooks
// =============================================================================

/**
 * @DEVELOPERS
 * The fun stuff!
 */
const useProps = (): Props => {
  const [provider, setProvider] = useState<PhantomSolanaProvider | null>(null);
  const [network, setNetwork] = useState<string>(SupportedSolanaChainIds.SolanaMainnet);
  const [connection, setConnection] = useState(new Connection(getConnectionUrl(network)));
  const { logs, createLog, clearLogs, toggleLogs, logsVisibility } = useLogs();
  const { logAutoConnectErrors, onlyIfTrusted, setLogAutoConnectErrors, setOnlyIfTrusted } = usePreferences();

  useEffect(() => {
    (async () => {
      // sleep for 100 ms to give time to inject
      await sleep(100);
      setProvider(getProvider());
    })();
  }, []);

  useEffect(() => {
    if (!provider) return;

    // attempt to eagerly connect
    provider.connect({ onlyIfTrusted }).catch((error) => {
      if (logAutoConnectErrors) {
        createLog({
          status: 'error',
          method: 'connect',
          message: `Eager connect failed: ${error.message}`,
        });
      }
    });

    provider.on('connect', (publicKey: PublicKey) => {
      createLog({
        status: 'success',
        method: 'connect',
        message: `Connected to account ${publicKey.toBase58()}`,
      });
    });

    provider.on('disconnect', () => {
      createLog({
        status: 'warning',
        method: 'disconnect',
        message: '👋',
      });
    });

    provider.on('accountChanged', (publicKey: PublicKey | null) => {
      if (publicKey) {
        createLog({
          status: 'info',
          method: 'accountChanged',
          message: `Switched to account ${publicKey.toBase58()}`,
        });
      } else {
        /**
         * In this case dApps could...
         *
         * 1. Not do anything
         * 2. Only re-connect to the new account if it is trusted
         *
         * ```
         * provider.connect({ onlyIfTrusted: true }).catch((err) => {
         *  // fail silently
         * });
         * ```
         *
         * 3. Always attempt to reconnect
         */

        createLog({
          status: 'info',
          method: 'accountChanged',
          message: 'Attempting to switch accounts.',
        });

        provider.connect().catch((error) => {
          createLog({
            status: 'error',
            method: 'accountChanged',
            message: `Failed to re-connect: ${error.message}`,
          });
        });
      }
    });

    return () => {
      provider.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createLog, provider]);

  /** SignAndSendTransactionV0WithLookupTable */
  const handleSignAndSendTransactionV0WithLookupTable = useCallback(async () => {
    if (!provider) return;
    try {
      const [lookupSignature, lookupTableAddress] = await createAddressLookupTable(
        provider.publicKey,
        connection,
        await connection.getLatestBlockhash().then((res) => res.blockhash),
        provider
      );
      createLog({
        status: 'info',
        method: 'signAndSendTransactionV0WithLookupTable',
        message: `Signed and submitted transactionV0 to make an Address Lookup Table ${lookupTableAddress} with signature: ${lookupSignature}. Please wait for 5-7 seconds after signing the next transaction to be able to see the next transaction popup. This time is needed as newly appended addresses require one slot to warmup before being available to transactions for lookups.`,
      });
      await pollSolanaSignatureStatus([lookupSignature], connection, createLog);
      const extensionSignature = await extendAddressLookupTable(
        provider.publicKey,
        connection,
        await connection.getLatestBlockhash().then((res) => res.blockhash),
        lookupTableAddress,
        provider
      );
      createLog({
        status: 'info',
        method: 'signAndSendTransactionV0WithLookupTable',
        message: `Signed and submitted transactionV0 to extend Address Lookup Table ${extensionSignature}.`,
      });
      await pollSolanaSignatureStatus([extensionSignature], connection, createLog);
      const signature = await signAndSendTransactionV0WithLookupTable(
        provider.publicKey,
        connection,
        await connection.getLatestBlockhash().then((res) => res.blockhash),
        lookupTableAddress,
        provider
      );
      createLog({
        status: 'info',
        method: 'signAndSendTransactionV0WithLookupTable',
        message: `Signed and submitted transactionV0 with Address Lookup Table ${signature}.`,
      });
      pollSolanaSignatureStatus([signature], connection, createLog).then(() => {
        toast.success('Transaction confirmed');
      });
    } catch (error) {
      toast.error("Transaction couldn't be confirmed");
      createLog({
        status: 'error',
        method: 'signAndSendTransactionV0WithLookupTable',
        message: error.message,
      });
    }
  }, [createLog, provider, connection]);

  /** Connect */
  const handleConnect = useCallback(async () => {
    if (!provider) return;

    try {
      await provider.connect();
    } catch (error) {
      createLog({
        status: 'error',
        method: 'connect',
        message: error.message,
      });
    }
  }, [createLog, provider]);

  /** SignIn */
  const handleSignIn = useCallback(async () => {
    if (!provider) return;
    const signInData = await createSignInData();

    try {
      const { address, account, signedMessage, signature } = await signIn(signInData, provider);
      const message = new TextDecoder().decode(signedMessage);
      const decodedSignature = new TextDecoder().decode(signature);
      createLog({
        status: 'success',
        method: 'signIn',
        message: `Message signed: ${message} by ${address || account.address} with signature ${decodedSignature}`,
      });
    } catch (error) {
      createLog({
        status: 'error',
        method: 'signIn',
        message: error.message,
      });
    }
  }, [createLog, provider]);

  /** SignInError */
  const handleSignInError = useCallback(async () => {
    if (!provider) return;
    const signInData = await createSignInErrorData();

    try {
      const { address, account, signedMessage, signature } = await signIn(signInData, provider);
      const message = new TextDecoder().decode(signedMessage);
      const decodedSignature = new TextDecoder().decode(signature);
      createLog({
        status: 'success',
        method: 'signMessage',
        message: `Message signed: ${message} by ${address || account.address} with signature ${decodedSignature}`,
      });
    } catch (error) {
      createLog({
        status: 'error',
        method: 'signIn',
        message: error.message,
      });
    }
  }, [createLog, provider]);

  /** Disconnect */
  const handleDisconnect = useCallback(async () => {
    if (!provider) return;

    try {
      await provider.disconnect();
    } catch (error) {
      createLog({
        status: 'error',
        method: 'disconnect',
        message: error.message,
      });
    }
  }, [createLog, provider]);

  /** Network Switch */
  const handleNetworkSwitch = useCallback(
    async (newNetwork: string) => {
      try {
        const newConnection = new Connection(getConnectionUrl(newNetwork), 'confirmed');
        setConnection(newConnection);
        setNetwork(newNetwork);
        createLog({
          status: 'success',
          message: `Switched to ${newNetwork} network`,
        });
      } catch (error) {
        console.log('catch error');
        createLog({
          status: 'error',
          message: error.message,
        });
      }
    },
    [createLog, setConnection, setNetwork]
  );

  const connectedMethods = useMemo(() => {
    return [
      {
        name: 'Sign and Send Transaction (v0 + Lookup table)',
        chainIds: [SupportedSolanaChainIds.SolanaMainnet],
        onClick: handleSignAndSendTransactionV0WithLookupTable,
      },
      {
        name: 'Sign In',
        chainIds: [SupportedSolanaChainIds.SolanaMainnet],
        onClick: handleSignIn,
      },
      {
        name: 'Sign In Error',
        chainIds: [SupportedSolanaChainIds.SolanaMainnet],
        onClick: handleSignInError,
      },
      {
        name: 'Disconnect',
        chainIds: [SupportedSolanaChainIds.SolanaMainnet],
        onClick: handleDisconnect,
      },
    ];
  }, [handleSignAndSendTransactionV0WithLookupTable, handleSignIn, handleSignInError, handleDisconnect]);

  return {
    publicKey: provider?.publicKey || null,
    connectedMethods,
    handleConnect,
    handleSignIn,
    logs,
    clearLogs,
    toggleLogs,
    logsVisibility,
    handleNetworkSwitch,
    network,
    logAutoConnectErrors,
    toggleLogAutoConnectErrors: () => setLogAutoConnectErrors((prev) => !prev),
    onlyIfTrusted,
    toggleOnlyIfTrusted: () => setOnlyIfTrusted((prev) => !prev),
  };
};

// =============================================================================
// Stateless Component
// =============================================================================

const App = () => {
  const {
    publicKey,
    connectedMethods,
    handleConnect,
    handleSignIn,
    logs,
    clearLogs,
    logsVisibility,
    toggleLogs,
    handleNetworkSwitch,
    network,
    logAutoConnectErrors,
    toggleLogAutoConnectErrors,
    onlyIfTrusted,
    toggleOnlyIfTrusted,
  } = useProps();

  return (
    <AppWrapper>
      <Sidebar
        logsVisibility={logsVisibility}
        toggleLogs={toggleLogs}
        topSection={<SolanaNetworkSelector network={network} setNetwork={handleNetworkSwitch} />}
        activePath="/sol-advanced-sandbox"
      >
        {publicKey && <ConnectedAs addresses={{ evm: null, solana: publicKey.toBase58() }} />}
        {!publicKey && (
          <ConnectActions>
            <div>
              <Button onClick={handleConnect} data-testid="connect">
                Connect to Phantom
              </Button>
              <TestId id="connect" />
            </div>
            <div>
              <Button onClick={handleSignIn} data-testid="signIn">
                Sign Into Phantom
              </Button>
              <TestId id="signIn" />
            </div>
          </ConnectActions>
        )}
        <ActionButtons connectedMethods={connectedMethods} connected={!!publicKey} />
        <CheckboxWithLabel
          dataTestId="only-if-trusted-checkbox"
          isEnabled={onlyIfTrusted}
          toggle={toggleOnlyIfTrusted}
          label={'Use onlyIfTrusted with connect'}
        />
        <CheckboxWithLabel
          dataTestId="log-eager-connect-checkbox"
          isEnabled={logAutoConnectErrors}
          toggle={toggleLogAutoConnectErrors}
          label={'Show eager connect error logs'}
        />
      </Sidebar>
      {logsVisibility && <Logs connected={!!publicKey} logs={logs} clearLogs={clearLogs} />}
    </AppWrapper>
  );
};

const WrappedApp = () => (
  <PreferencesProvider>
    <LogsProvider>
      <App />
    </LogsProvider>
  </PreferencesProvider>
);

export default WrappedApp;
