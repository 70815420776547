import React from 'react';
import styled from 'styled-components';
import { GRAY } from '../constants';

const Label = styled.label`
  font-size: 14px;
  color: ${GRAY};
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const Input = styled.input`
  margin-right: 5px;
  cursor: pointer;
`;

export const CheckboxWithLabel: React.FC<{
  isEnabled: boolean;
  toggle: () => void;
  label: string;
  dataTestId?: string;
}> = ({ isEnabled, toggle, label, dataTestId }) => {
  return (
    <div>
      <Label>
        <Input data-testid={dataTestId} type="checkbox" checked={isEnabled} onChange={toggle} />
        {label}
      </Label>
    </div>
  );
};
