import React from 'react';
import styled from 'styled-components';
import { GRAY } from '../../constants';
import { useDev } from '../../hooks/useDev';
const Text = styled.div`
  color: ${GRAY};
  font-size: 8px;
`;

export function TestId({ id }: { id: string }) {
  const { dev } = useDev();

  if (!dev) {
    return null;
  }

  return <Text>data-testid: {id}</Text>;
}
