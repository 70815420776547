import { TransactionResponse, Web3Provider } from '@ethersproject/providers';
import { utils } from 'ethers';
import { ERC_20_TOKENS, TOKEN_LOCKER } from '../../constants/tokens';
import { parseEther } from 'viem';

/**
 * Sends 1 wei to yourself
 * @param {Web3Provider} provider a web3 provider object
 * @returns {Promise<TransactionResponse>} a raw transaction object (hasn't been confirmed by network)
 */
export async function approveERC20(
  chainId: string,
  provider: Web3Provider,
  amount = parseEther('100')
): Promise<TransactionResponse> {
  try {
    const signer = provider.getSigner();
    const address = await signer.getAddress();
    const gasPrice = await provider.getGasPrice();
    const tokenAddress = ERC_20_TOKENS[chainId];
    const contractApproveTo = TOKEN_LOCKER[chainId];

    if (!tokenAddress || !contractApproveTo) {
      throw new Error('Token address not found');
    }

    // Encode the function signature and parameters
    const approveFunctionSignature = utils.id('approve(address,uint256)').slice(0, 10);
    const encodedParameters = utils.defaultAbiCoder.encode(['address', 'uint256'], [contractApproveTo, amount]);
    const data = approveFunctionSignature + encodedParameters.slice(2); // Remove the '0x' from encodedParameters

    const transactionParameters = {
      gasPrice, // customizable by user during confirmation.
      // gasLimit: utils.hexlify(100000),
      to: tokenAddress, // Required except during contract publications.
      from: address,
      value: 0, // Only required to send ether to the recipient from the initiating external account.
      data: data, // Call to mint() function
      chainId: parseInt(chainId),
    };

    return signer.sendTransaction(transactionParameters);
  } catch (error) {
    console.warn(error);
    throw new Error(error.message);
  }
}

export async function revokeERC20(chainId: string, provider: Web3Provider): Promise<TransactionResponse> {
  return approveERC20(chainId, provider, BigInt(0));
}
