import { Web3Provider } from '@ethersproject/providers';
import { ERC_20_TOKENS, TOKEN_LOCKER } from '../../constants/tokens';
import { SUPPORTED_CHAINS } from '../../constants/chains';

export const signEIP2612 = async (chainId: string, provider: Web3Provider): Promise<string> => {
  try {
    const signer = provider.getSigner();
    const address = await signer.getAddress();

    const token = ERC_20_TOKENS[chainId];

    const locker = TOKEN_LOCKER[chainId];

    if (!token || !locker) {
      throw new Error('Token address not found');
    }

    const expiry = Math.floor((Date.now() + 1000 * 60 * 60 * 24 * 365) / 1000);

    return provider.provider.request({
      method: 'eth_signTypedData_v4',
      params: [
        address,
        {
          domain: {
            chainId: parseInt(SUPPORTED_CHAINS[chainId].hexChainId, 16),
            name: 'Token',
            verifyingContract: token,
            version: '1',
          },
          message: {
            expiry: expiry,
            nonce: 1,
            spender: locker,
            holder: address,
            allowed: true,
          },
          primaryType: 'Permit',
          types: {
            EIP712Domain: [
              { name: 'name', type: 'string' },
              { name: 'version', type: 'string' },
              { name: 'chainId', type: 'uint256' },
              { name: 'verifyingContract', type: 'address' },
            ],
            Permit: [
              { name: 'holder', type: 'address' },
              { name: 'spender', type: 'address' },
              { name: 'nonce', type: 'uint256' },
              { name: 'expiry', type: 'uint256' },
              { name: 'allowed', type: 'bool' },
            ],
          },
        },
      ],
    });
  } catch (error) {
    console.warn(error);
    throw new Error(error.message);
  }
};
