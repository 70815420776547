import { Web3Provider } from '@ethersproject/providers';
import { SUPPORTED_CHAINS } from '../../constants/chains';

export const signTypedDataV1 = async (chainId: string, provider: Web3Provider, message: any): Promise<string> => {
  try {
    const signer = provider.getSigner();
    const address = await signer.getAddress();

    return provider.provider.request({
      method: 'eth_signTypedData',
      params: [
        [
          {
            type: 'string', // Any valid solidity type
            name: 'Message', // Any string label you want
            value: message, // The value to sign
          },
          {
            type: 'uint32',
            name: 'A number',
            value: '1337',
          },
          {
            type: 'string',
            name: 'Chain ID',
            value: SUPPORTED_CHAINS[chainId].hexChainId,
          },
        ],
        address,
      ],
    });
  } catch (error) {
    console.warn(error);
    throw new Error(error.message);
  }
};
