import { Web3Provider } from '@ethersproject/providers';
import { SUPPORTED_CHAINS } from '../../constants/chains';

export const signTypedDataV3 = async (chainId: string, provider: Web3Provider): Promise<string> => {
  try {
    const signer = provider.getSigner();
    const address = await signer.getAddress();

    return provider.provider.request({
      method: 'eth_signTypedData_v3',
      params: [
        address,
        {
          types: {
            EIP712Domain: [
              { name: 'name', type: 'string' },
              { name: 'version', type: 'string' },
              { name: 'chainId', type: 'uint256' },
              { name: 'verifyingContract', type: 'address' },
            ],
            Person: [
              { name: 'name', type: 'string' },
              { name: 'wallet', type: 'address' },
            ],
            Mail: [
              { name: 'from', type: 'Person' },
              { name: 'to', type: 'Person' },
              { name: 'contents', type: 'string' },
            ],
          },
          primaryType: 'Mail',
          domain: {
            name: 'Ether Mail',
            version: '1',
            chainId: parseInt(SUPPORTED_CHAINS[chainId].hexChainId, 16),
            verifyingContract: '0xCcCCccccCCCCcCCCCCCcCcCccCcCCCcCcccccccC',
          },
          message: {
            from: {
              name: 'Cow',
              wallet: '0xCD2a3d9F938E13CD947Ec05AbC7FE734Df8DD826',
            },
            to: {
              name: 'Phantom',
              wallet: '0xbBbBBBBbbBBBbbbBbbBbbbbBBbBbbbbBbBbbBBbB',
            },
            contents: 'Hello, Phantom!',
          },
        },
      ],
    });
  } catch (error) {
    console.warn(error);
    throw new Error(error.message);
  }
};
