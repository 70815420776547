import { PhantomSolanaProvider } from '../../types';
import { WalletContextState } from '@solana/wallet-adapter-react';

const signMessage = async (
  message: string,
  provider?: PhantomSolanaProvider,
  walletContext?: WalletContextState
): Promise<Uint8Array | string> => {
  try {
    const encodedMessage = new TextEncoder().encode(message);
    if (provider) return await provider.signMessage(encodedMessage);
    if (walletContext) return await walletContext.signMessage(encodedMessage);
    throw new Error('No provider or wallet context found');
  } catch (error) {
    console.warn(error);
    throw new Error(error.message);
  }
};

export default signMessage;
