import React from 'react';
import Button from '../Button';
import { ConnectedMethods } from '../../types';
import styled from 'styled-components';
import { GRAY, PURPLE, WHITE } from '../../constants';
import { hexToRGB } from '../../utils';
import { CHAINS } from '../../constants/chains';
import { TestId } from '../TestId';

const Tag = styled.p`
  text-align: center;
  color: ${GRAY};

  a {
    color: ${PURPLE};
    text-decoration: none;

    ::selection {
      color: ${WHITE};
      background-color: ${hexToRGB(PURPLE, 0.5)};
    }

    ::-moz-selection {
      color: ${WHITE};
      background-color: ${hexToRGB(PURPLE, 0.5)};
    }
  }

  @media (max-width: 320px) {
    font-size: 14px;
  }

  ::selection {
    color: ${WHITE};
    background-color: ${hexToRGB(PURPLE, 0.5)};
  }

  ::-moz-selection {
    color: ${WHITE};
    background-color: ${hexToRGB(PURPLE, 0.5)};
  }
`;

const ChainIcon = styled.img`
  height: ${(props) => props.height};
  width: ${(props) => props.height};
  border-radius: 6px 0 0 6px;
`;

const ChainHeader = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  margin: 5px 0 10px;
`;

const Actions = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 5px;
  width: 100%;
`;

export function ActionButtons({
  connected,
  connectedMethods,
}: {
  connected: boolean;
  connectedMethods: ConnectedMethods[];
}) {
  if (!connected) {
    return null;
  }
  return (
    <>
      {CHAINS.filter((chain) => {
        // Return chains that are represented in the connected methods
        return connectedMethods.some((method) => method.chainIds.includes(chain.chainId));
      }).map((chain) => {
        return (
          <>
            <ChainHeader>
              <ChainIcon src={chain.icon} height="16px" style={{ marginRight: '6px', borderRadius: '6px' }} />
              <Tag>{chain.name}</Tag>
            </ChainHeader>
            <Actions>
              {connectedMethods
                .filter((method) => method.chainIds.includes(chain.chainId))
                .map((method, i) => (
                  <div>
                    <Button
                      data-testid={`${method.name.toLowerCase().split(' ').join('-')}`}
                      key={`${chain.chainId}-${method.name}-${i}`}
                      onClick={() => method.onClick(chain.chainId)}
                      disabled={method.disabled}
                    >
                      {method.name}
                    </Button>
                    <TestId id={`${chain.chainId}-${method.name.toLowerCase().split(' ').join('-')}`} />
                  </div>
                ))}
            </Actions>
          </>
        );
      })}
    </>
  );
}
